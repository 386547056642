.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
h3{
  margin: 0px;
}
button{
  border: none;
}
.cat-buttons-home{
  background-color: #FFFFFF;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border: none;
    box-shadow: 2px 2px 7px #bbb;
}
.row-flex{
  margin: 0px;
    padding: 2px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

/* Nav bar style */
.nav-container{
  display: flex;
  align-items: center;
}
.nav-logos{
  width: 50px;
}
.display-flex-nav{
  display: flex;
    flex: 1 1 auto;
}
.nav-background{
  background: #1c1c2f;
}

.nav-background-login{
  background: linear-gradient(45deg, #528ad1, transparent)
}
.Links {
  display: flex;
  justify-content: flex-end;
  margin-right: 36px;
}
.mx-auto{
  flex: auto;
}
.Links a {
  text-decoration: none;
  color: #e09a4e;
  margin: 0 30px; /* Reduced spacing between links */
}

.Links a:hover {
  text-decoration: underline;
}

.Links a:hover {
  text-decoration: underline;
}
.navheader-toggles-styling{
  color: #000;
  font-weight: 600;
}
/* src/Loader.css */
.loader-fixed {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* background: '#00000080'; */
  background-color: #00000080;

}
.loader-container{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* background: '#00000080'; */
  background-color: #00000080;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  /* border-top-color: #007bff; */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.day-box-style{
  box-shadow: 2px 2px 7px #bbb;
  padding: 5px;
  border-radius: 8px;
}

/* src/LoginScreen.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
  /* background-color: #f8f9fa; */
  /* padding: 20px; */
}
.loginDiv{
  margin: 10px;
  box-shadow: 2px 2px 7px #bbb;
  width: 95%;
  padding: 10px;
  max-width: 400px;
  text-align: center;
  border-radius: 10px;
}

.login-image {
  width: 125px;
  height: 125px;
  margin-bottom: 1px;
}

.login-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin: 0px;
}

.bold-text {
  font-size: 14px;
  font-weight: bold;
  color: #030303;
  margin-top: 5px;
}

.input-container {
  width: 100%;
  max-width: 400px;
}

.error-text {
  color: red;
  margin-bottom: 10px;
}

.action-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  margin-top: 10px;
}

.link-button {
  background: none;
  border: none;
  color: #4474b1;
  cursor: pointer;
  text-decoration: underline;
}

.sign-in-button {
  background-color: #4474b1;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  width: 25%;
  max-width: 400px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}

.toggle-password {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
}
.toggle-button {
  background: none;
  border: none;
  /* color: #007bff; */
  cursor: pointer;
}


/* src/Components/Inputs/TextArea.css */
.input-container2 {
  position: relative;
  width: 100%;
}

.input-field {
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 0 20px;
  /* margin: 10px 0; */
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  padding-right: 40px; /* Ensure there's space for the right element */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-field::placeholder {
  color: #999;
}

.right-element {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
/* src/views/NFPPage/NFPRegistrationScreen.css */
.nfp-title{
  text-align: center;
  margin: 0px;
}
.nfp-registration-container {
  /* max-width: 850px;
  margin: 0 auto; */
  padding: 20px;  
  /* background-image: url("./Assets/reg-bg-img.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  background-attachment: fixed;
  overflow: hidden;
}

.form-container {
  max-width: 850px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 7px #bbb;
  padding: 20px;
  border-radius: 10px;
  background: white;
}
.form-container-user {
  max-width: 630px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 7px #bbb;
  padding: 5px 30px;
  border-radius: 10px;
  background: white;
}

.form-label {
  font-weight: 400;
    /* margin-top: 10px; */
    position: relative;
    top: 10px;
    left: 20px;
    background: white;
    width: fit-content;
    font-size: 14px;
}
.form-label-2{
  font-weight: 500;
  /* padding-bottom: 5px; */
}
.input-phone{
  border: none;
  outline: none;
}
@media only screen and (min-width: 20px) and (max-width: 340px) {
  .form-input {
    /* margin-bottom: 10px; */
    padding: 15px;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    /* min-width: 250px; */
    border: none;
    box-shadow: 2px 2px 7px #bbb;
    outline: none;
    /* width: 100%; */
  }
  
  .form-input-2 {
    /* margin-bottom: 10px; */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* min-width: 250px; */
    outline: none;
    /* width: 100%; */
  }
}
@media only screen and (min-width: 341px) and (max-width: 3340px) {
  .form-input {
    /* margin-bottom: 10px; */
    padding: 15px;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    min-width: 250px;
    border: none;
    box-shadow: 2px 2px 7px #bbb;
    outline: none;
    /* width: 100%; */
  }
  
  .form-input-2 {
    /* margin-bottom: 10px; */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-width: 250px;
    outline: none;
    /* width: 100%; */
  }
}




.input-group {
  display: flex;
  align-items: center;
  gap: 5px;
}

.input-group .form-input {
  flex: 1;
  margin-left: 10px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
}

.category-section {
  margin-top: 10px;
}

.hour-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.time-input {
  width: 100px;
  /* margin: 0 10px; */
  padding:8px 5px;
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  border-radius: 10px;
  border: none;
  box-shadow: 2px 2px 7px #bbb;
  outline: none;
}
.form-buttons{
  padding-top: 15px;
}

.add-button, .cancel-button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  /* margin-right: 10px; */
}
.submit-button{
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 120px;
  max-width: 400px;
}

.cancel-button {
  background-color: #6c757d;
}

.error-text {
  color: red;
  margin-top: 10px;
  text-align: center;
}

.success-text {
  color: green;
  margin-top: 10px;
}

.row-display-flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* align-items: center; */
  gap: 10px;
  padding-bottom: 10px;
}
.flex-auto{
  flex: auto;
  display: flex;
  flex-direction: column;
}
/* src/components/CustomDropdown.css */
.dropdown-container {
  position: relative;
  /* margin-bottom: 10px; */
}
.down-arrow{
  width: 10px;
  height: 10px;
}
.dropdown {
  /* padding: 8px; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.dropdown-text {
  color: #666;
}

.dropdown-icon {
  margin-left: 10px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
 .dropdown-options-time{
  position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    bottom: -70px;
    min-height: 240px;
    max-height: 323px;
    overflow-y: scroll;
 }

.dropdown-option {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.dropdown-option:last-child {
  border-bottom: none;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}

.cat-list-div{
  display: flex;
  flex-direction: row;
  gap: 10;
  flex-wrap: wrap;
}

/* src/components/CustomCheckBox.css */
.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  flex-wrap: wrap;
}

.checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checked {
  background-color: #007BFF;
}

.checkmark {
  color: #fff;
}

.checkbox-label {
  font-size: 16px;
  padding-right: 8px;
}
/* src/views/NFPPage/NFPDashboardScreen.css */
.safe-area {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  background-color: #fff;
  width: 100%;
  max-width: 728px;
  margin: 20px auto;
  box-shadow: 2px 2px 7px #bbb;
  padding: 20px;
}
.safe-area2 {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  background-color: #fff;
  width: 100%;
  max-width: 700px;
  margin: 20px auto;
  box-shadow: 2px 2px 7px #bbb;
  padding: 20px;
}

.container {
  padding: 16px;
  flex-grow: 1;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.welcome-container {
  margin-bottom: 10px;
}

.welcome-text {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 10px;
  gap: 10px;
}

/* .button {
  background-color: #f0eded;
  border-radius: 50px;
  margin: 4px;
  width: 150px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.button-content {
  display: flex;
  align-items: center;
  gap: 5px;
}

.button-image {
  width: 30px;
  height: 30px;
}

.button-text {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.section {
  margin-bottom: 16px;
}

.card {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.label {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.text {
  font-size: 16px;
  color: #333;
}

.contact-container {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 8px;
  cursor: pointer;
}

.contact-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.error-text {
  color: red;
  margin-top: 10px;
  text-align: center;
}

/* src/views/NFPPage/NFPInventoryListScreen.css */


/* .scroll-view {
  flex: 1;
  padding: 16px;
  margin-bottom: 95px;
  overflow-y: auto;
} */

.category-container {
  margin-top: 15px;
}

.category-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  background-color: #f5f5f5;
  padding: 5px 5px 5px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.category-image {
  width: 25px;
  height: 25px;
  margin-right: 8px;
margin-bottom:5px;
}

.category-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  flex: 1;
}

.arrow-image {
  width: 20px;
  height: 20px;
  /* margin-left: 8px; */
  padding-right: 12px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  background-color: #ddd;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.header-text {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.item-name-header {
  flex: 2;
}

.quantity-header {
  flex: 1;
}

.action-header {
  flex: 1;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 4px;
}

.even-row {
  background-color: #f9f9f9;
}

.odd-row {
  background-color: #e9e9e9;
}

.cell {
  font-size: 13px;
  color: #333;
  text-align: left;
  font-weight: 500;
  padding-left: 10px;

}

.item-name-cell {
  flex: 2;
  text-transform: capitalize;
}

.quantity-cell {
  flex: 1;
}

.action-cell {
  flex: 1;
}

.edit-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.error-text {
  text-transform: capitalize;
  color: red;
  text-align: center;
  margin: 20px 0;
}

.floating-button {
  position: fixed;
  right: 16px;
  bottom: 16px;
  background-color: #1e90ff;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.subtitle {
  font-size: 18px;
  text-align: center;
  color: #000;
  font-weight: bold;
  margin: 2px 0;
}

.title-headings {
  font-size: 18px;
  text-align: center;
  color: #000;
  font-weight: bold;
  margin: 2px 0;
}
/* src/views/NFPPage/NFPInventoryAddScreen.css */


.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  width: 100%;
  max-width: 400px;
  height: 50px;
  border-radius: 25px;
  padding: 0 20px;
  /* margin: 10px 0; */
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.category-title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  color: #333;
}

.categories-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
}

.category-card{
  background-color: #E0E0E0;
  padding: 10px;
  border-radius: 50px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 100px;
    height: 18px;
}

.selected-category {
  background-color: grey;
}

/* .category-image {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
} */

.category-text {
  color: #333;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  width: 80%;
  max-height: 80%;
  display: flex;
  flex-direction: column;
}

.cross-button {
  align-self: flex-end;
  padding: 5px;
  border: none;
  background: none;
}

.cross-button-image {
  width: 20px;
  height: 20px;
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  color: #333;
}

.subcategories-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.sub-category-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.sub-category-card {
  background-color: grey;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.selected-sub-category {
  background-color: #218838;
}

.sub-category-text {
  color: #fff;
  font-size: 16px;
  text-align: center;
}

/* .button {
  background-color: #007BFF;
  padding: 10px 20px;
  border-radius: 25px;
  margin: 5px 0;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 60px;
} */

.error-text {
  color: red;
  margin-bottom: 10px;
  text-align: center;
}

.success-text {
  color: green;
  margin-bottom: 10px;
  text-align: center;
}

.breadcrumb {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px;
  color: #333;
}

/* src/views/NFPPage/NFPInventoryUpdateScreen.css */


.container {
  flex: 1;
  padding: 20px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .input-container {
  width: 100%;
  padding: 10px;
} */

.label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}



.suggestion-container {
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.suggestion-text {
  font-size: 16px;
  color: #333;
}

.footer {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  background-color: #1E90FF;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border: none;
}

.error-text {
  text-transform: capitalize;
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.success-text {
  color: green;
  text-align: center;
  margin-bottom: 10px;
}

/* src/views/AuthenticationPages/ForgotPasswordScreen.css */


/* .container {
  flex: 1;
  background-color: #f0f0f0;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.back-button {
  align-self: flex-start;
  padding: 10px;
  border: none;
  background: none;
  cursor: pointer;
}

.back-button-text {
  font-size: 24px;
  color: #007BFF;
}

.header {
  text-align: center;
  margin-bottom: 5%;
}

.title {
  font-size: 2.0rem;
  font-weight: bold;
  color: #000;
}

.subtitle {
  font-size: 13px;
  color: #b4a8a8;
  margin-top: 10px;
  margin-bottom: 20px;
}

/* .input-container {
  width: 100%;
  margin-bottom: 5%;
} */



.submit-button {
  background-color: #007BFF;
  border-radius: 25px;
  padding: 10px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-text {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.submit-button-text {
  font-size: 16px;
}


/* Donation List Screen Style */

/* .safe-area {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
} */

.DL-container {
  padding: 16px;
  padding-bottom: 60px;
  /* overflow-y: auto; */
}

.dropdown-container {
  /* margin-bottom: 20px; */
}

.dropdown-button {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: none;
  cursor: pointer;
}

.dropdown-button-text {
  font-size: 16px;
  color: #333;
}

.dropdown-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  transition: transform 0.3s;
}

.dropdown-icon.rotated {
  transform: rotate(180deg);
}

.dropdown-menu {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 5px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  width:30%;
}

.dropdown-menu-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.dropdown-menu-item:last-child {
  border-bottom: none;
}

.section-header {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  min-width: 220px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.section-header-text {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.donation-card {
  background-color: #E0E0E0;
  padding: 16px;
  border-radius: 8px;
  margin-top: 10px;
}

.error-text {
  color: red;
  margin-bottom: 10px;
  text-align: center;
}

.no-data-text {
  text-align: center;
  font-size: 16px;
  color: red;
  padding: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-width: 500px;
}

.selected-date-text {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: #FFFFFF;
}

.text {
  font-size: 16px;
  color: #333;
  flex: 1;
}

.contact-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex: 1;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.contact-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.contact-text {
  font-size: 16px;
  color: #333;
  flex: 1;
}

/*nfp SuperAdmin*/

/* GlobalCss.css */
.nfp-admin-screen {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header-nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f0f0f0;
}

.tabs-container {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  background-color: #f0f0f0;
  flex-wrap: wrap;
}

.tab {
  padding: 10px 20px;
  margin: 0 4px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background-color: #fff;
}

.selected-tab {
  background-color: #1E90FF;
  color: #fff;
}
.content-container2 {
  /* padding: 20px;
  flex: 1;
  overflow-y: auto; */
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  background-color: #fff;
  width: 100%;
  max-width: 1000px;
  margin: 20px auto;
  box-shadow: 2px 2px 7px #bbb;
  padding: 20px;
  margin-bottom: 100px;
}


.content-container {
  flex: 1; /* This makes the content-container take up the remaining space */
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 2px 2px 7px #bbb;
  max-width: 1000px;
  margin-top: 20px;
}

.info-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 8px 0;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.feed-back{
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 10px;
  margin: 8px 0;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.info-details {
  flex: 1;
  cursor: pointer;
}

.approve-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #1E90FF;
  color: #fff;
  cursor: pointer;
}

.error-text {
  color: red;
  text-align: center;
}

.success-text {
  color: green;
  text-align: center;
}
.loader-s {
  margin-left: 10px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
}

.loader {
  margin-left: 10px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

.loader-large {
  margin: auto;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.floating-button2 {
  position: fixed;
  bottom: 60px;
  right: 20px;
  width: 56px;
  height: 56px;
  background-color: #1E90FF;
  color: #fff;
  border-radius: 28px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 40px;
  margin-right: 200px;
}

.custom-footer {
  background-color: #f0f0f0;
  padding: 10px 20px;
  text-align: center;
  border-top: 1px solid #ddd;
  /* width: 100%; */
  position: relative; /* Relative positioning ensures it respects the normal flow */
  bottom: 0; /* Push it to the bottom */
}
.nfp-admin-screen {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it takes the full height of the viewport */
}

/*calender code */
.container-calender {
  display: flex;
  flex-direction: column;
  width: 60%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 6px #A0A1A6;
  padding: 10px;
}

.container.small {
  width: 80%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.month-control {
  display: flex;
  align-items: center;
}

.arrow-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E7E8E9;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.arrow-icon {
  width: 100%;
  height: 100%;
}

.header-text {
  font-size: 14px;
  margin: 0 10px;
}

.close-button {
  cursor: pointer;
}

.close-icon {
  width: 20px;
  height: 20px;
}

.day-headers {
  display: flex;
  justify-content: space-between;
}

.day-header {
  flex: 1;
  text-align: center;
  font-size: 16px;
}

.calendar {
  display: flex;
  flex-wrap: wrap;
}

.day {
  width: 14.28%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.selected-day {
  background-color: orange;
  border-radius: 60%;
}


.input-with-icon {
  position: relative;
  display: inline-block;
}

.form-input {
  width: 100%;
  padding-right: 40px; /* Adjust based on icon size */
  box-sizing: border-box;
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust based on icon size */
  transform: translateY(-50%);
  width: 20px; /* Adjust icon size */
  height: 20px; /* Adjust icon size */
  cursor: pointer;
}

.calendar-overlay {
  position: absolute;
  top: 60%;
  /* left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px; */
}

/*Camp Inventory Styling*/
.form-container-ci {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-container-ci {
  width: 100%;
  margin: 10px 0;
  position: relative;
}

/* .input {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  padding: 0 20px;
  margin: 10px 0;
  background-color: #fff;
  color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
} */

.button-ci {
  background-color: #007BFF;
  padding: 10px 20px;
  border-radius: 25px;
  margin: 5px 0;
  width: 60%;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.error-text {
  color: #FF0000;
  /* margin-bottom: 10px; */
  text-align: center;
  text-transform: capitalize;
}

.success-text {
  color: #28A745;
  margin-bottom: 10px;
  text-align: center;
}

.dropdown-header-ci {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 53%;
  border-radius: 20px;
  padding: 0 20px;
  margin: 10px 0;
  background-color: #fff;
  color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.selected-time-text-ci {
  font-size: 14px;
  color: rgba(18, 21, 32, 0.3);
}

.arrow-icon-ci {
  width: 18px;
  height: 18px;
}

.rotate-icon-ci {
  transform: rotate(180deg);
}

.dropdown-content-ci {
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 10px;
  background-color: #f9f9f9;
  width:50%;
}

.dropdown-content-ci-reg {
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 10px;
  background-color: #f9f9f9;
  width:98%;
}

.dropdown-item-ci {
  margin-bottom: 10px;
}

.add-button {
  background-color: #007BFF;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  width:100%
}

.spinner-ci {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.time-dropdown-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.star-color{
  color:red;
  font-size: 18px;
}
.form-select {
  outline:none;
  width:100%;
  border:none
}

.program-details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
}

.location-dropdown {
  width: 300px;
  padding: 5px;
  font-size: 16px;
}

.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.privacy-container h1, .privacy-container h2 {
  color: #333333;
}

.privacy-container p, .privacy-container li {
  color: #555555;
  line-height: 1.6;
}

.privacy-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.privacy-container a {
  color: #007bff;
  text-decoration: none;
}

.privacy-container a:hover {
  text-decoration: underline;
}

.support-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;  
  background-image: url("./Assets/reg-bg-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  background-attachment: fixed;
}

.support-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.support-content h1 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.support-info {
  margin-bottom: 20px;
}

.support-info h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #555;
}

.support-info p {
  font-size: 16px;
  color: #555;
}

.support-info a {
  color: #007bff;
  text-decoration: none;
}

.support-info a:hover {
  text-decoration: underline;
}






.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007BFF; /* Blue background */
  color: white; /* White text */
  border-radius: 4px; /* Rounded corners */
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border: none; /* Remove border */
}

.file-info {
  margin-top: 10px;
  font-size: 12px;
  color: #666; /* Grey color for the text */
  margin-left: 15px;
}

.location-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card-header {
  /* margin-bottom: 5px; */
  font-weight: 500;
  font-size: 18px;
}

.card-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* The switch - container */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #007BFF;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.inline-spinner {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #fff;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}











